import React from "react"
import styles from "./HistoryPartners.module.css"

const HistoryPartners = () => {
    return (
        <>        
        <div className={styles.coverImg} id="history">
            <div className={styles.title}>History of Business Partners</div>
        </div>
        <div className={styles.container}>
            <div className={styles.content}>
                <ul>
                    <li>Grandeur Property Management Co. Ltd.</li>
                    <li>Hang Yick Properties Management Limited</li>
                    <li>Hong Kong Housing Authority</li>
                    <li>Hong Kong Housing Society</li>
                    <li>Hong Kong Productive Council</li>
                    <li>Hong Yip Service Co. Ltd.</li>
                    <li>IFC Management Co. Ltd.</li>
                    <li>Kai Shing Management Services Ltd.</li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default HistoryPartners;