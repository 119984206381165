import React from "react"
import styles from "./OrganizationStructure.module.css"
import orgStructure from "./org_structure.png"

const OrganizationStructure = () => {
    return (
        <>        
        <div className={styles.coverImg} id="orgStructure">
            <div className={styles.title}>Organization Structure</div>
        </div>
        <div className={styles.container}>
            <div className={styles.content}>                
                <img src={orgStructure} style={{maxWidth: '100%'}} alt="organization_structure" />
            </div>            
        </div>
        </>
    )
}

export default OrganizationStructure;