import React from "react"
import styles from "./SideMenu.module.css"


const SideMenu = () => {
    return (
        <>
        <div className={styles.container}>
            <div className={styles.title}>Menu</div>
            <div className={styles.menuItemContainer}>
                <div className={styles.menuItem}><a href="#intro">Introduction</a></div>
                <div className={styles.menuItem}><a href="#orgStructure">Organization Structure</a></div>
                <div className={styles.menuItem}><a href="#siteOrgStructure">Site Organization Structure</a></div>
                <div className={styles.menuItem}><a href="#mgtExp">Management Experience</a></div>
                <div className={styles.menuItem}><a href="#serviceScope">Scope of Service</a></div>
                <div className={styles.menuItem}><a href="#existingJobRef">Existing Job Reference</a></div>        
                <div className={styles.menuItem}><a href="#serviceExp">Service Experience</a></div>
                <div className={styles.menuItem}><a href="#history">History of Business Partners</a></div>
                <div className={styles.menuItem}><a href="#uniformSample">Uniform Sample</a></div>
                <div className={styles.menuItem}><a href="#contact">Contact Us</a></div>
            </div>
        </div>
        </>
    )
}

export default SideMenu