import React from "react"
import styles from "./UniformSample.module.css"
import uniformMaleFront from "./uniform_male_front.png"
import uniformMaleBack from "./uniform_female_back.png"
import uniformFemaleFront from "./uniform_female_front.png"
import uniformFemaleBack from "./uniform_female_back.png"

const UniformSample = () => {
    return (
        <>        
        <div className={styles.coverImg} id="uniformSample">
            <div className={styles.title}>Sample of Uniform</div>
        </div>
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.uniformContainer}>
                    <img src={uniformMaleFront} style={{maxWidth: '50%'}} alt="uniform-male-front" />
                    <img src={uniformMaleBack} style={{maxWidth: '50%'}} alt="uniform-male-back" />
                </div>
                <div className={styles.uniformContainer}>
                    <img src={uniformFemaleFront} style={{maxWidth: '50%'}} alt="uniform-female-front" />
                    <img src={uniformFemaleBack} style={{maxWidth: '50%'}} alt="uniform-female-back" />
                </div>
            </div>            
        </div>
        </>
    )
}

export default UniformSample;