import React, {useState, useEffect} from 'react';
import Header from "./Header/Header";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Intro from './Intro/Intro';
import HistoryPartners from './HistoryPartners/HistoryPartners';
import ServiceExp from './ServiceExp/ServiceExp';
import ServiceScope from './ServiceScope/ServiceScope';
import UniformSample from './UniformSample/UniformSample';
import ManagementExp from './ManagementExp/ManagementExp';
import ExistingJobRef from './ExistingJobRef/ExistingJobRef';
import OrganizationStructure from './OrganizationStructure/OrganizationStructure';
import SiteOrganizationStructure from './SiteOrganizationStructure/SiteOrganizationStructure';
import Content from './Content/Content';


function App() {
  // const [scrollPosition, setScrollPosition] = useState(0);
  const [hideHeader, setHideHeader] = useState(true);

  const handleScroll = () => {
    const position = window.pageYOffset;    
    if (position > 100) {
      setHideHeader(false);
    } else {
      setHideHeader(true);
    }
    // setScrollPosition(position);
};

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])
  return (
    <div>      
      <Router>
        <Header hideHeader={hideHeader}></Header>        
        <Switch>
          <Route exact path="/intro">
            <Intro></Intro>
          </Route>
          <Route exact path="/history">
            <HistoryPartners></HistoryPartners>
          </Route>
          <Route exact path="/serviceExp">
            <ServiceExp></ServiceExp>
          </Route>
          <Route exact path="/serviceScope">
            <ServiceScope></ServiceScope>
          </Route>
          <Route exact path="/uniform">
            <UniformSample></UniformSample>
          </Route>
          <Route exact path="/mgtExp">
            <ManagementExp></ManagementExp>
          </Route>
          <Route exact path="/existingJobRef">
            <ExistingJobRef></ExistingJobRef>
          </Route>
          <Route exact path="/orgStructure">
            <OrganizationStructure></OrganizationStructure>
          </Route>
          <Route exact path="/siteOrgStructure">
            <SiteOrganizationStructure></SiteOrganizationStructure>
          </Route>   
          <Route path="/">
            <Content></Content>
          </Route>          
        </Switch>
      </Router>
    </div>
  );
}

export default App;
