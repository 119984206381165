import React from "react"
import styles from "./ServiceScope.module.css"
import Separator from "../Separator/Separator"

const ServiceScope = () => {
    return (
        <div className={styles.container} id="serviceScope">
            <div className={styles.title}>Scope of Service</div>
            <Separator></Separator>
            <div className={styles.subtitle}>Cleaning Service</div>            
            <div className={styles.serviceContainer}>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/dailyCleaning.png" /></div>
                    <div className={styles.caption}>Daily General Cleaning</div>
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/periodicCleaning.png" /></div>
                    <div className={styles.caption}>Periodic Cleaning</div>                    
                </div>
                <div className={styles.serviceItem}>                    
                    <div className={styles.photo}><img src="./img/externalCurtinWallCleaning.png" /></div>
                    <div className={styles.caption}>External Curtin Wall Cleaning</div>
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/glassPanelCleaning.png" /></div>
                    <div className={styles.caption}>Glass Panels Cleaning</div>                    
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/hiLevelCleaning.png" /></div>
                    <div className={styles.caption}>High Level Cleaning</div>                                        
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/carpetTreatment.png" /></div>
                    <div className={styles.caption}>Carpet Treatment (Shampoo Cleaning)</div>                    
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/marbleMaintenance.png" /></div>
                    <div className={styles.caption}>Marble Maintenance (Floor Polishing, Waxing, Restoration)</div>                    
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/waterTankCleaning.png" /></div>
                    <div className={styles.caption}>Water Tanks / Confine Space Cleaning</div>                    
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/liftPitCleaning.png" /></div>
                    <div className={styles.caption}>Lift Pit Cleaning</div>                
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/pestControl.png" /></div>
                    <div className={styles.caption}>Pest Control / Services</div>                    
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/chandelierCleaning.png" /></div>
                    <div className={styles.caption}>Chandelier Cleaning</div>                                        
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/kitchenCleaning.png" /></div>
                    <div className={styles.caption}>Kitchen Cleaning</div>                                                            
                </div>
            </div>            
     
            <div className={styles.subtitle}>Waste Management</div>

            <div className={styles.serviceContainer}>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/compactorRental.png" /></div>
                    <div className={styles.caption}>Skip and Compactor Rental Services</div>
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/hookLiftLoading.png" /></div>
                    <div className={styles.caption}>Hydraulic Hook-Lift Loading</div>                    
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/grabLoading.png" /></div>
                    <div className={styles.caption}>Grab Loading</div>                                        
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/septicTank.png" /></div>
                    <div className={styles.caption}>Septic Tank and Grease-trap Desludging</div>                                        
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/wasteCollection.png" /></div>
                    <div className={styles.caption}>Industrial and Domestic Waste Collection and Disposal</div>                                        
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/streetWashing.png" /></div>
                    <div className={styles.caption}>Street Washing Cleaner</div>                                        
                </div>
                <div className={styles.serviceItem}>
                    <div className={styles.photo}><img src="./img/airDisinfection.png" /></div>
                    <div className={styles.caption}>Air Disinfection and VOC and Formaldehyde Removal Service</div>                                        
                </div>            
            </div>  
        </div>
    )
}

export default ServiceScope;