import React from "react"
import styles from "./SiteOrganizationStructure.module.css"
import siteOrgStructure from "./site_org_structure.png"

const SiteOrganizationStructure = () => {
    return (
        <>        
        <div className={styles.coverImg} id="siteOrgStructure">
            <div className={styles.title}>Site Organization Structure</div>
        </div>
        <div className={styles.container}>
            <div className={styles.content}>
                <img src={siteOrgStructure} style={{maxWidth: '100%'}} alt="site-organization-structure" />
            </div>            
        </div>
        </>
    )
}

export default SiteOrganizationStructure