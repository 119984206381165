import React from "react"
import styles from "./Separator.module.css"


const Separator = () => {
    return (
        <div className={styles.separatorContainer}>
            <div className={styles.separator}></div>
        </div>
    )
}

export default Separator