import React, {useState, useEffect} from "react"
import { NavLink } from "react-router-dom";
import styles from "./Header.module.css"
import logo from "./logo.png"
import SideMenu from '../SideMenu/SideMenu';
import { MdMenu } from "react-icons/md";

const Header = (props) => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    return (
        <>
        <div className={styles.container} hide={props.hideHeader ? 'true' : 'false'}>     
            <div className={styles.content}>
                <div className={styles.logo}><img src={logo} alt="logo" /></div>
                <div className={styles.links}>
                    <span><a href="#intro">Introduction</a></span>
                    | <span><a href="#orgStructure">Organization Structure</a></span>
                    | <span><a href="#siteOrgStructure">Site Organization Structure</a></span>
                    | <span><a href="#mgtExp">Management Experience</a></span>
                    | <span><a href="#serviceScope">Scope of Service</a></span>
                    | <span><a href="#existingJobRef">Existing Job Reference</a></span>        
                    | <span><a href="#serviceExp">Service Experience</a></span>
                    | <span><a href="#history">History of Business Partners</a></span>
                    | <span><a href="#uniformSample">Uniform Sample</a></span>
                    | <span><a href="#contact">Contact Us</a></span>
                </div>
                <div className={styles.menuBtn} onClick={toggleMenu}>
                    <MdMenu size={30} color={props.hideHeader ? '#FFF' : '#000'}></MdMenu>                    
                </div>
            </div>                   
        </div>

        <div className={styles.menuOverlay} onClick={toggleMenu} hide={showMenu ? 'false' : 'true'}>
            
        </div>
        <div className={styles.menu} hide={showMenu ? 'false' : 'true'}>
            <SideMenu></SideMenu>
        </div>            
        </>
    )
}

export default Header;