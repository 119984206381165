import React from "react"
import styles from "./Cover.module.css"

const Cover = () => {
    return (
        <div className={styles.container}>
            Sanwo
        </div>
    )
}

export default Cover