import React from "react"
import styles from "./Contact.module.css"

const Contact = () => {
    return (
        <>
        <div className={styles.coverImg} id="contact">
            <div className={styles.title}>Contact Us</div>
        </div>
        <div className={styles.container}>
            <div className={styles.contactInfo}>
                <div className={styles.contactInfoLeft}>
                    <div className={styles.row}>
                        <span>Address: </span>
                        <span>ROOM D12, 4/F., NO.1 WALUNT STREET, TAI KOK TSUI, KOWLOON, HONG KONG</span>
                    </div>
                    <div className={styles.row}>
                        <span>Tel: </span>
                        <span>3705 1095</span>
                    </div>
                    <div className={styles.row}>
                        <span>Fax: </span>
                        <span>3705 1065</span>
                    </div>
                    <div className={styles.row}>
                        <span>Email: </span>
                        <span><a href="mailto: sanwoservices@gmail.com">sanwoservices@gmail.com</a></span>
                    </div>
                </div>
                <div className={styles.mapContainer}>            
                    <iframe className={styles.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.814905600551!2d114.16055751495512!3d22.322838885311896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400b73904bceb%3A0x5e1be8061fbfd494!2z5aSn6KeS5ZKA5ZCI5qGD6KGXMeiZn-emj-aYjOW3peW7oOWkp-W7iA!5e0!3m2!1szh-TW!2shk!4v1593762109600!5m2!1szh-TW!2shk" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </div>
        </div>
        </>
    )
}

export default Contact