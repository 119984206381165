import React from "react"
import styles from "./ExistingJobRef.module.css"

const ExistingJobRef = () => {
    return (
        <>
        
        <div className={styles.coverImg} id="existingJobRef">
            <div className={styles.title}>Existing Job Reference</div>
        </div>

        <div className={styles.container}>    
            <div className={styles.content}>
                <ul>
                    <li>SASA Branch</li>
                    <li>China United Centre</li>
                    <li>Yan Lok Building</li>
                    <li>Hong Man industrial Centre</li>
                    <li>Cheung Tat CTR</li>
                    <li>Cheung Lee Industrial Building</li>
                    <li>Sherwood Court</li>
                    <li>Bonham Court</li>
                    <li>Hoseinee House</li>
                    <li>Vogue Building</li>
                    <li>Graff Dimonds Branch</li>
                    <li>Pola Branch</li>
                    <li>FEHD</li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default ExistingJobRef