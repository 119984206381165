import React from "react"
import styles from "./ManagementExp.module.css"
import Separator from "../Separator/Separator"

const ManagementExp = () => {
    return (
        <>
        
        <div className={styles.coverImg} id="mgtExp">
            <div className={styles.title}>Experience of the Management / Supervisory Team</div>
        </div>
                
        <div className={styles.container}>
            
            <div className={styles.content}>
                Mr. Fong Wai - Managing Director of San Wo Cleaning Service Company Limited, 
                Mr. Fong has over 20 years experience in cleaning industry. He has strong hands-on experience with downstream industrial cleaning services. 
                He clean for general offices, dental practices and event venues, schools, nursery and much more.
            </div>
        </div>
        
        </>
    )
}

export default ManagementExp