import React from "react"
import styles from "./Content.module.css"
import Intro from "../Intro/Intro"
import ServiceScope from "../ServiceScope/ServiceScope"
import Cover from "../Cover/Cover"
import ManagementExp from "../ManagementExp/ManagementExp"
import ExistingJobRef from "../ExistingJobRef/ExistingJobRef"
import ServiceExp from "../ServiceExp/ServiceExp"
import HistoryPartners from "../HistoryPartners/HistoryPartners"
import UniformSample from "../UniformSample/UniformSample"
import OrganizationStructure from "../OrganizationStructure/OrganizationStructure"
import SiteOrganizationStructure from "../SiteOrganizationStructure/SiteOrganizationStructure"
import Contact from "../Contact/Contact"

const Content = () => {
    return (
        <div className={styles.container}>      
            <Cover></Cover>      
            <Intro></Intro>
            <OrganizationStructure></OrganizationStructure>
            <SiteOrganizationStructure></SiteOrganizationStructure>
            <ServiceScope></ServiceScope>
            <ManagementExp></ManagementExp>
            <ExistingJobRef></ExistingJobRef>
            <ServiceExp></ServiceExp>
            <HistoryPartners></HistoryPartners>
            <UniformSample></UniformSample>
            <Contact></Contact>
        </div>
    )
}

export default Content