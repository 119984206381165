import React from "react";
import styles from "./Intro.module.css"
import Separator from "../Separator/Separator";

const Intro = () => {
    return (
        <div className={styles.container} id="intro">
            <div className={styles.title}>About Us</div>
            <Separator></Separator>
            <div className={styles.content}>
                <div>San Wo Cleaning Service Company Limited incorporated in 2020,is developed from “ San Wo Cleaning Service Company” founded in 2012. Our incorporation reflects expansion and growth of our business in cleaning sector.
                    Cleaning workers to provide regular office cleaning service and different deep-cleaning services such as floor washing, floor waxing, carpet cleaning, Air Disinfection and VOC and Formaldehyde Removal Service and so on.The purpose of  San Wo Cleaning Service Company Limited is to provide professional cleaning service to the public in Hong Kong.
                </div>
                <div>The customer network is in whole Hong Kong. There are many cleaning workers in San Wo Cleaning Service Company Limited</div>
                <div>We promise to provide professional and good services to our customers.</div>
            </div>
        </div>
    )
}

export default Intro;