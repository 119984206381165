import React from "react"
import styles from "./ServiceExp.module.css"

const ServiceExp = () => {
    return (
        <>        
        <div className={styles.coverImg} id="serviceExp">
            <div className={styles.title}>Service Experience</div>
        </div>
        <div className={styles.container}>
            <div className={styles.content}>
                <ul>
                    <li>Folli Follie</li>
                    <li>GIENNE TSUI</li>
                    <li>Hatwoman</li>
                    <li>Continental Diamond </li>
                    <li>BYPAC Pearls and Cashmere </li>
                    <li>BNZS</li>
                    <li>Initial</li>
                    <li>Jessica</li>
                    <li>TITIKAKA</li>
                    <li>Asia Container Terminals Ltd.</li>
                    <li>AT&T Global Network Services Hong Kong LTD</li>
                    <li>Chow Sang Sang Jewelry Co. Ltd.</li>
                    <li>Chow Tai Fook Jewelry Co. Ltd.</li>
                    <li>Apple Store</li>
                </ul>
            </div>
        </div>
        </>
    )
}

export default ServiceExp;